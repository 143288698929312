import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../layout';

const NotFoundPage: React.FC = () => (
  <Layout headerColorScheme="light">
    <p>
      Page not found <Link to="/">Go home</Link>
    </p>
  </Layout>
);

export default NotFoundPage;
